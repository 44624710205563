<script setup lang="ts">
/**
Woom specific color picker
*/

const props = defineProps({
    /**
       * Array of options to display
       */
    options: {
        type: Array,
        required: true,
    },
    /**
     * Preselect one of the options - if not set the first item will be selected
     */
    selected: {
        type: String,
    },
    /**
     * Size of the buttons
     */
    size: {
        type: [String, Number],
        default: 46,
    },
    /**
     * Display defines if the component should be a button or just display the colour
     */
    display: {
        type: Boolean,
        default: false,
    },
    /**
     * Limit the amount of options to display
     */
    limit: {
        type: [Number, String],
    },
    /**
     * stacked defines if the options should be stacked or not
     */
    stacked: {
        type: Boolean,
        default: false,
    },
});


const current = ref(props.selected ? props.selected : props.options[0]);


const trueLimit = computed(() => {
    return Number(props.limit) + 1;
});

const colorClass = () => {
    const color = type.toLowerCase().replace(/[\s]/g, '-');
    return `product-color-${color}`;
};

const handleSelected = (opt) => {
    current.value = opt;
};

const emit = defineEmits(['changed']);

watch(
    () => current.value,
    () => {
        if (current.value) {
            emit('changed', current.value);
        }
    }
);

</script>

<template>
    <div class="w-color-options"
        :class="[stacked ? 'w-color-options--stacked' : null, size > 32 ? 'w-color-options--spacing-l' : 'w-color-options--spacing']">
        <template v-for="(opt, index) in options">
            <mol-color :key="index" :name="opt" @mouseover="handleSelected(opt)"
                :selected="display ? null : current === opt" :size="size" :display="display"
                v-if="trueLimit ? index < trueLimit : true" />
        </template>
        <span class="w-color-options-plus" v-if="trueLimit && options.length > Number(trueLimit)">+{{ options.length -
            Number(trueLimit) }}</span>
    </div>
</template>

<style lang="sass">
  .w-color-options
    display: flex
    align-items: center
    flex-wrap: wrap
  
    // plus
    &-plus
      font-size: var(--font-xs)
      color: var(--color-grey)
  
    // Spacing
    .w-color
      margin-right: var(--spacing-1)
  
      &:last-child
        margin-right: 0
    &--spacing-l
      .w-color
        margin-right: var(--spacing-2)
  
    &--stacked
      .w-color
        margin-right: -8px
  
      &.w-color-options--spacing-l
        .w-color
          margin-right: -16px
  </style>

  