<script setup lang="ts">
    const props = defineProps({
        /**
         * Align the hero content.
         *
         * @values left, center, right
         */
        align: {
            type: String,
            default: 'center',
        },
        /**
         * Vertical align the hero content.
         *
         * @values top, center, bottom
         */
        vertical: {
            type: String,
            default: 'bottom',
        },
        /**
         * Asset source object as received from the CMS - also check `src` if source is an URL/string
         */
        data: {
            type: Object,
            default: null,
        },
    });

    const align = computed(() => props.align);
    const vertical = computed(() => props.vertical);

    const assetDetails = computed(() => props.data);

    const heroAlign = computed(() => {
        switch (align.value) {
            case 'left':
                return 'hero--align-left';
            case 'center':
                return 'hero--align-center';
            case 'right':
                return 'hero--align-right';
            default:
                return 'hero--align-center';
        }
    });

    const heroVertical = computed(() => {
        switch (vertical.value) {
            case 'top':
                return 'hero--vertical-top';
            case 'center':
                return 'hero--vertical-middle';
            case 'bottom':
                return 'hero--vertical-bottom';
            default:
                return 'hero--vertical-bottom';
        }
    });
</script>
<template>
    <section class="hero w-full max-w-full">
        <div class="flex w-full max-w-full min-h-[75vh] relative">
            <picture class="w-full max-w-full h-full flex absolute">
                <img
                    class="w-full h-full object-cover"
                    :src="assetDetails.hero.data.src" />
            </picture>
            <div class="flex-1 h-full z-[2] w-full max-w-full mx-auto absolute">
                <div class="w-full max-w-full h-full flex items-center justify-start">
                    <div class="flex flex-col max-w-[310px] max-h-full relative left-40">
                        <div class="w-full py-2">
                            <atm-icon-logo-riders-club
                                :svg-width="110"
                                :svg-height="66"></atm-icon-logo-riders-club>
                        </div>
                        <h2
                            class="text-woom-white whitespace-break-spaces text-2xl"
                            v-html="assetDetails.title"></h2>
                        <div
                            class="inline-flex flex-wrap text-woom-white mb-2"
                            v-html="assetDetails.subtitle"></div>

                        <template v-if="assetDetails.link && assetDetails.link.label">
                            <div class="inline-flex w-full my-1 mx-auto gap-3pb-6 sm:pb-0 md:pb-0 lg:pb-0">
                                <atm-woom-button
                                    class="rounded-md text-sm font-bold flex items-center justify-center bg-woom-white w-full min-h-[40px]">
                                    {{ assetDetails.link.label }}
                                </atm-woom-button>
                                <atm-woom-button
                                    class="flex items-center justify-center border-white border text-sm font-bold text-woom-white rounded-md bg-transparent w-full min-h-[40px]">
                                    watch video
                                </atm-woom-button>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<style>
    /* Alignment */
    .hero--align-left {
        @apply items-start;
    }
    .hero--align-center {
        @apply content-end justify-start;
    }
    .hero--align-right {
        @apply items-end text-right;
    }

    /*   Vertical Alignment */
    .hero--vertical-top {
        @apply items-start;
    }
    .hero--vertical-middle {
        @apply justify-center;
    }
    .hero--vertical-bottom {
        @apply justify-center;
    }
</style>
