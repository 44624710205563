<script setup lang="ts">
    import { Swiper, SwiperSlide } from 'swiper/vue';

    import 'swiper/css';
    import 'swiper/css/pagination';
    import 'swiper/css/navigation';
    import { Pagination, Navigation } from 'swiper/modules';

    const props = defineProps({
        /**
         * Asset source object as received from the CMS - also check `src` if source is an URL/string
         */
        data: {
            type: Object,
            default: null,
        },
    });

    const assets = computed(() => props.data);

    const mainSwiper = ref(null);
    const paginationSwiper = ref(null);

    const setOnSwiper = (swiper: any) => {
        return (mainSwiper.value = swiper);
    };

    const onSlideChange = (swiper: any) => {
        return (paginationSwiper.value = swiper);
    };
</script>

<template>
    <section class="flex w-full max-w-full min-h-[75vh] relative">
        <div class="w-full max-w-full">
            <swiper
                :slides-per-view="1"
                :navigation="{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }"
                :loop="true"
                :lazy="true"
                :pagination="{ clickable: true, dynamicBullets: true }"
                :keyboard="{
                    enabled: true,
                }"
                :style="{
                    '--swiper-navigation-color': '#fff',
                    '--swiper-pagination-color': '#fff',
                }"
                direction="horizontal"
                :modules="[Pagination, Navigation]"
                @swiper="setOnSwiper"
                @slideChange="onSlideChange">
                <template v-if="assets.length && assets.length > 1">
                    <swiper-slide
                        v-for="(asset, index) in assets"
                        :key="index">
                        <org-hero :data="asset"></org-hero>
                    </swiper-slide>
                </template>
                <template v-else>
                    <org-hero :data="assets"></org-hero>
                </template>
            </swiper>
            <button class="swiper-button-prev nav-button-prev"></button>
            <button class="swiper-button-next nav-button-next"></button>
        </div>
    </section>
</template>

<style scoped>
    .nav-button-prev,
    .nav-button-next {
        @apply bg-opacity-0 bg-transparent text-gray-200;
    }

    .swiper-button-prev::after .nav-button-prev,
    .swiper-button-next::after .nav-button-next {
        @apply text-lg md:text-2xl lg:text-3xl opacity-40 hover:opacity-50 hover:text-gray-600;
    }

    .swiper-pagination-clickable .swiper-pagination-bullet {
        @apply text-gray-50 transition-opacity;
    }
</style>
