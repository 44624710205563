<script setup lang="ts">
    import { computed, ref } from 'vue';
    import { Swiper, SwiperSlide } from 'swiper/vue';
    import { Navigation, Scrollbar } from 'swiper/modules';
    import 'swiper/css';
    import 'swiper/css/navigation';
    import 'swiper/css/scrollbar';

    const props = defineProps({
        /**
         * Array of asset source objects as received from the CMS
         */
        data: {
            type: Array,
            default: null,
        },
        title: {
            type: String,
            default: '',
        },
        description: {
            type: String,
            default: '',
        },
        link: {
            type: String,
            default: '',
        },
        linkText: {
            type: String,
            default: '',
        },
        textColor: {
            type: String,
            default: '',
        },
        backgroundColor: {
            type: String,
            default: '',
        },
        backgroundImage: {
            type: String,
            default: '',
        },
    });

    const customBackground = computed(() => {
        const backgroundColor = props.backgroundColor ? { backgroundColor: '#' + props.backgroundColor } : {};
        const backgroundImage = props.backgroundImage ? { backgroundImage: 'url(' + props.backgroundImage + ')' } : {};
        return {
            ...backgroundColor,
            ...backgroundImage,
        };
    });

    const carouselArr = ref(props.data);
    const mainSwiper = ref(null);
    const chosenColor = ref('basic');

    const carouselToShow = computed(() => {
        if (carouselArr.value.length > 3) {
            return carouselArr.value;
        }

        return [...carouselArr.value, ...carouselArr.value];
    });

    const slidesPerView = computed(() => {
        return carouselToShow.value.length >= 3 * 2 ? 3 : Math.floor(carouselToShow.value.length / 2);
    });

    const setMainSwiper = (swiper) => {
        mainSwiper.value = swiper;
    };

    const openShoppingCart = () => {
        console.log('openShoppingCart clicked');
    };

    const changeColorOption = (option: any) => {
        chosenColor.value = option.replace(' ', '-');
    };
</script>

<template>
    <div
        class="flex flex-col py-10 max-h-[85vh]"
        :style="customBackground">
        <span
            class="m-auto text-center w-[75%] max-w-[800px]"
            :class="textColor">
            <h2
                v-if="title"
                class="mb-2 font-bold text-4xl">
                {{ title }}
            </h2>
            <p v-if="description">
                {{ description }}
            </p>
        </span>

        <div class="w-full max-w-full flex overflow-hidden">
            <swiper
                class="carousel max-w-full flex-1 gap-4 md:gap-16 overflow-hidden"
                @swiper="setMainSwiper"
                :modules="[Navigation, Scrollbar]"
                :scrollbar="{ draggable: true, el: '.swiper-scrollbar' }"
                :navigation="{ prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next' }"
                :centered-slides="true"
                loop
                :slides-per-view="'auto'"
                :looped-slides="slidesPerView">
                <swiper-slide
                    v-slot="{ isActive }"
                    v-for="slide in carouselToShow"
                    class="overflow-hidden transition-all duration-300 ease-in-out !w-[75%] opacity-50 !scale-90 md:!w-[45%] md:!scale-75 [&.swiper-slide-active]:!scale-100 [&.swiper-slide-active]:opacity-100">
                    <div class="flex flex-col max-h-full m-auto pb-16">
                        <img
                            class="h-full w-full max-w-full max-h-full m-auto object-contain overflow-hidden"
                            :src="slide[chosenColor] || slide.image" />

                        <org-color-options
                            class="mx-auto mb-4 gap-1"
                            :class="[isActive ? '' : 'hidden md:flex']"
                            :size="30"
                            v-if="slide.colors?.length"
                            :options="slide.colors"
                            :selected="slide.colors[0]"
                            @changed="changeColorOption" />

                        <span
                            :class="[isActive ? '' : 'hidden md:inline', textColor]"
                            class="text-center font-bold">
                            {{ slide.title }}
                        </span>

                        <span
                            class="text-center m-auto max-w-[300px]"
                            v-if="slide.description">
                            {{ slide.description }}
                        </span>

                        <span
                            v-if="slide.weight"
                            class="flex flex-col justify-items-center content-center m-auto font-woomMono text-xs"
                            :class="[isActive ? '' : 'hidden md:flex', textColor]">
                            <span class="text-center">Bike {{ slide.wheels }}″ • {{ slide.weight.metric }} kg</span>
                            <span class="text-center"
                                >Child {{ slide.min_age }} - {{ slide.max_age }} years • {{ slide.min_height?.metric }} -
                                {{ slide.max_height?.metric }}cm</span
                            >
                            <span
                                v-if="slide.price"
                                class="text-center"
                                >{{ slide.price.currencyCode }} {{ slide.price.amount }},—</span
                            >
                        </span>

                        <div
                            class="flex max-w-full m-auto content-center justify-items-center py-2"
                            :class="[isActive ? '' : 'hidden md:flex']">
                            <atm-button
                                v-if="!slide.buttonLeft"
                                class="flex h-10 px-4 md:px-5 rounded text-white font-bold bg-woom-red items-center justify-center text-sm md:text-base cursor-pointer"
                                :ripple="false"
                                @click="openShoppingCart">
                                <span>Add to cart</span>
                            </atm-button>
                            <a
                                v-if="slide.buttonLeft"
                                class="m-auto h-10 px-4 md:px-5 rounded text-white font-bold items-center justify-center text-sm md:text-base flex"
                                :class="slide.buttonLeft.classes"
                                :ripple="false"
                                :href="slide.buttonLeft.link">
                                <span>{{ slide.buttonLeft.text }}</span>
                            </a>
                            <a
                                v-if="slide.buttonRight"
                                class="ml-2 h-10 px-4 md:px-5 rounded border-2 font-bold items-center justify-center flex"
                                :class="slide.buttonRight.classes"
                                :ripple="false"
                                :href="slide.buttonRight.link">
                                <span>{{ slide.buttonRight.text }}</span>
                            </a>
                            <a
                                v-if="slide.url && !slide.buttonRight"
                                class="ml-2 h-10 px-4 md:px-5 rounded border-2 font-bold items-center justify-center flex"
                                :class="textColor"
                                :ripple="false"
                                :href="'/' + slide.url.locale + slide.url.url">
                                <span>View details</span>
                            </a>
                        </div>
                    </div>
                </swiper-slide>
                <div
                    class="relative flex max-w-full items-center justify-items-center m-auto bottom-[80px] overflow-hidden min-h-[70px] md:max-w-[30%]">
                    <button
                        class="swiper-button-prev !m-auto !left-1 !relative !w-10 !h-10 bg-woom-white rounded-full after:!text-base after:text-woom-black shadow-[0_1px_5px_rgba(0,0,0,0.2),0_3px_1px_-2px_rgba(0,0,0,0.12),0_2px_2px_rgba(0,0,0,0.14)]"></button>
                    <div class="swiper-scrollbar flex-1 !left-0 m-auto !relative !mx-4 !bottom-0"></div>
                    <button
                        class="swiper-button-next !m-auto !right-1 !relative !w-10 !h-10 bg-woom-white rounded-full after:!text-base after:text-woom-black shadow-[0_1px_5px_rgba(0,0,0,0.2),0_3px_1px_-2px_rgba(0,0,0,0.12),0_2px_2px_rgba(0,0,0,0.14)]"></button>
                </div>
                <div
                    v-if="link"
                    class="underline text-center m-auto w-full absolute bottom-0 z-10">
                    <a
                        class="underline text-center m-auto"
                        :href="link"
                        >{{ linkText }}</a
                    >
                </div>
            </swiper>
        </div>
    </div>
</template>
