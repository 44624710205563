<script setup lang="ts">
    /**
A simple color button that can be used to display a color or color option.
*/
    const props = defineProps({
        /**
         * Color name
         */
        name: {
            type: String,
            required: true,
        },
        /**
         * Selected color
         */
        selected: {
            type: Boolean,
            default: false,
        },
        /**
         * Size of the button
         */
        size: {
            type: [String, Number],
            default: 46,
        },
        /**
         * Disabled state - when disabled no event will be triggered
         */
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Display defines if the component should be a button or just display the colour
         */
        display: {
            type: Boolean,
            default: false,
        },
    });

    const colorClass = computed(() => {
        const color = props.name.toLowerCase().replace(/[\s/]/g, '-');
        return `w-color-${color}`;
    });

    const formattedSize = computed(() => {
        return props.size + 'px';
    });

    const component = computed(() => {
        return props.display ? 'div' : 'button';
    });

    const emit = defineEmits(['click']);

    const handleSelected = (name) => {
        if (props.disabled || props.display) {
            return;
        }
        emit('click', name);
    };
</script>

<template>
    <component
        :is="component"
        class="w-color cursor-pointer"
        :class="[colorClass, selected ? 'w-color-selected' : null, disabled ? 'w-color-disabled' : null, display ? 'w-color-display' : null]"
        :title="name"
        :style="{ width: formattedSize, height: formattedSize }"
        :disabled="disabled"
        @click="handleSelected(name)">
        <icon-check></icon-check>
    </component>
</template>

<style>
    .w-color {
        border-radius: 50%;
        border-style: solid;
        border-width: 3px;
        outline: none;
        border-color: var(--color-white);
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--color-black);
        transition: transform 0.05s ease-in-out;
    }

    .w-color-woom-red,
    .w-color-red {
        @apply bg-woom-red border-woom-red;
    }

    .w-color .v-icon .fill,
    .w-color .icon-svg .fill {
        fill: var(--color-white) !important;
    }

    .w-color.w-color-black {
        background-color: #000000;
        border-color: #000000;
        color: var(--color-white);
    }

    .w-color.w-color-woom-red {
        background-color: #eb0613;
        border-color: #eb0613;
    }

    .w-color.w-color-red {
        background-color: #ff0000;
        border-color: #ff0000;
    }

    .w-color.w-color-electric-blue {
        background-color: #006ad1;
        border-color: #006ad1;
    }

    .w-color.w-color-woom-blue {
        background-color: #0095db;
        border-color: #0095db;
    }

    .w-color.w-color-woom-green {
        background-color: #279c38;
        border-color: #279c38;
    }

    .w-color.w-color-woom-yellow {
        background-color: #fab900;
        border-color: #fab900;
    }

    .w-color.w-color-woom-yellow .v-icon .fill,
    .w-color.w-color-woom-yellow .icon-svg .fill {
        fill: #000000 !important;
    }

    .w-color.w-color-woom-purple {
        background-color: #9406ff;
        border-color: #9406ff;
    }

    .w-color.w-color-woom-white,
    .w-color.w-color-white,
    .w-color.w-color-off-white {
        background-color: var(--color-white);
        border: 1px solid #000000;
    }

    .w-color.w-color-woom-white .v-icon .fill,
    .w-color.w-color-woom-white .icon-svg .fill,
    .w-color.w-color-white .v-icon .fill,
    .w-color.w-color-white .icon-svg .fill,
    .w-color.w-color-off-white .v-icon .fill,
    .w-color.w-color-off-white .icon-svg .fill {
        fill: #000000 !important;
    }

    .w-color.w-color-woom-orange {
        background-color: #ff5722;
        border-color: #ff5722;
    }

    .w-color.w-color-moon-grey {
        background-color: #707372;
        border-color: #707372;
    }

    .w-color.w-color-moon-light-grey {
        background-color: #707070;
        border-color: #707070;
    }

    .w-color.w-color-midnight-blue {
        background-color: #003c71;
        border-color: #003c71;
    }

    .w-color.w-color-mint-green {
        background-color: #00c389;
        border-color: #00c389;
    }

    .w-color.w-color-sky-blue {
        background-color: #0095db;
        border-color: #0095db;
    }

    .w-color.w-color-sunny-yellow {
        background-color: #fab900;
        border-color: #fab900;
    }

    .w-color.w-color-sunny-yellow .v-icon .fill,
    .w-color.w-color-sunny-yellow .icon-svg .fill {
        fill: #000000 !important;
    }

    .w-color.w-color-purple-haze {
        background-color: #9406ff;
        border-color: #9406ff;
    }

    .w-color.w-color-woom-pink {
        background-color: pink;
        border-color: pink;
    }

    .w-color.w-color-woom-pink .v-icon .fill,
    .w-color.w-color-woom-pink .icon-svg .fill {
        fill: #000000 !important;
    }

    .w-color.w-color-woom-grey {
        background-color: #d6d6d6;
        border-color: #d6d6d6;
    }

    .w-color.w-color-grey {
        background-color: #a9a9a9;
        border-color: #a9a9a9;
    }

    .w-color.w-color-silver {
        background-color: #f5f5f5;
    }

    .w-color.w-color-silver .v-icon .fill,
    .w-color.w-color-silver .icon-svg .fill {
        fill: #000000;
    }

    .w-color.w-color-mercury-grey {
        background-color: #e5e5e5;
        border-color: #e5e5e5;
    }

    .w-color.w-color-light-grey {
        background-color: #d8d8d8;
        border-color: #d8d8d8;
    }

    .w-color.w-color-pink {
        background-color: #ffc0cb;
        border-color: #ffc0cb;
    }

    .w-color.w-color-pink .v-icon .fill,
    .w-color.w-color-pink .icon-svg .fill {
        fill: #000000;
    }

    .w-color.w-color-power-pink {
        background-color: #f372a6;
        border-color: #f372a6;
    }

    .w-color.w-color-power-pink .v-icon .fill,
    .w-color.w-color-power-pink .icon-svg .fill {
        fill: #ffffff;
    }

    .w-color.w-color-navy {
        background-color: #000080;
        border-color: #000080;
    }

    .w-color.w-color-yellow {
        background-color: #ffff00;
        border-color: #ffff00;
    }

    .w-color.w-color-yellow .v-icon .fill {
        fill: #000000 !important;
    }

    .w-color.w-color-green {
        background-color: #00a695;
        border-color: #00a695;
    }

    .w-color.w-color-blue {
        background-color: #0057b8;
        border-color: #0057b8;
    }

    .w-color.w-color-moss-green,
    .w-color.w-color-moss-green---formular-red,
    .w-color.w-color-moss-green-formular-red,
    .w-color.w-color-moss-green---formula-red,
    .w-color.w-color-moss-green-formula-red {
        background-color: #aab991;
        border-color: #aab991;
    }

    .w-color.w-color-moss-green .v-icon .fill,
    .w-color.w-color-moss-green .icon-svg .fill,
    .w-color.w-color-moss-green---formular-red .v-icon .fill,
    .w-color.w-color-moss-green---formular-red .icon-svg .fill,
    .w-color.w-color-moss-green-formular-red .v-icon .fill,
    .w-color.w-color-moss-green-formular-red .icon-svg .fill,
    .w-color.w-color-moss-green---formula-red .v-icon .fill,
    .w-color.w-color-moss-green---formula-red .icon-svg .fill,
    .w-color.w-color-moss-green-formula-red .v-icon .fill,
    .w-color.w-color-moss-green-formula-red .icon-svg .fill {
        fill: #000000 !important;
    }

    .w-color.w-color-ice-blue,
    .w-color.w-color-ice-blue---carbon-black,
    .w-color.w-color-ice-blue-carbon-black {
        background-color: #e6f5f0;
        border-color: #e6f5f0;
    }

    .w-color.w-color-ice-blue .v-icon .fill,
    .w-color.w-color-ice-blue .icon-svg .fill,
    .w-color.w-color-ice-blue---carbon-black .v-icon .fill,
    .w-color.w-color-ice-blue---carbon-black .icon-svg .fill,
    .w-color.w-color-ice-blue-carbon-black .v-icon .fill,
    .w-color.w-color-ice-blue-carbon-black .icon-svg .fill {
        fill: #000000 !important;
    }

    .w-color.w-color-terra-coppa,
    .w-color.w-color-terra---coppa {
        background-color: #aa7a5b;
        border-color: #aa7a5b;
    }

    .w-color.w-color-anniversary-red,
    .w-color.w-color-anniversary---red {
        background-color: #d5092d;
        border-color: #d5092d;
    }

    .w-color:hover {
        transform: scale(1.1);
    }

    .w-color.w-color-disabled,
    .w-color.w-color-disabled:hover {
        cursor: not-allowed;
        opacity: 0.2;
        transform: scale(1);
    }

    .w-color.w-color-active,
    .w-color.w-color-selected,
    .w-color:active,
    .w-color.w-color-selected:hover {
        border-color: #a9a9a9;
        transform: scale(1);
    }

    .w-color.w-color-active.w-color-grey,
    .w-color:active.w-color-grey,
    .w-color.w-color-selected:hover.w-color-grey {
        border-color: #000000;
    }

    .w-color.w-color-active.w-color-black,
    .w-color.w-color-active.w-color-midnight-blue,
    .w-color:active.w-color-black,
    .w-color:active.w-color-midnight-blue,
    .w-color.w-color-selected:hover.w-color-black,
    .w-color.w-color-selected:hover.w-color-midnight-blue {
        box-shadow: inset 0 2px 2px 0 rgba(255, 255, 255, 0.7);
    }

    .w-color.w-color-display:hover {
        transform: scale(1);
    }
</style>
